import * as React from "react";
import { AppContext, AppContextType, VersionContext, VersionContextType } from "../../Context";
import Header from "../Header/Header";
import MapControl from "../MapComponent/MapControl";
import "./AuthenticatedContent.css";
import SearchResults from "../SearchResults/SearchResults";
import MeasurementControl from "../MapTools/Measurement/MeasurementControl";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/rootReducer";
import FeatureViewer from "../FeatureViewer/FeatureViewer";
import CreateObjectControl from "../MapTools/CreateObject/CreateObjectControl";

export function AuthenticatedContent() {
    const [currentVersion, setCurrentVersion] = React.useState<__esri.VersionInfoJSON>();
    const [mapView, setMapView] = React.useState<__esri.MapView>();
    const [showVersionMgmt, setShowVersionMgmt] = React.useState<boolean>(false);
    const [searchResults, setSearchResults] = React.useState<__esri.SearchViewModelSearchResponseResults[]>([]);

    const [selectedFeature, setSelectedFeature] = React.useState<__esri.Graphic | null>(null);

    const measurementToolOpen = useSelector((state: StoreState) => state.app.measureToolOpen);
    const createObjectOpen = useSelector((state: StoreState) => state.app.createObjectOpen);

    const versionContextStore = {
        showVersionMgmt: {
            set: setShowVersionMgmt,
            value: showVersionMgmt,
        },
        currentVersion: {
            set: setCurrentVersion,
            value: currentVersion,
        },
        mapView: {
            set: setMapView,
            value: mapView,
        },
    } as VersionContextType;

    const appContextStore = {
        selectedFeature: {
            set: setSelectedFeature,
            value: selectedFeature,
        },
    } as AppContextType;

    const memoVersionContextStore = React.useMemo(() => versionContextStore, [versionContextStore]);
    const memoAppContextStore = React.useMemo(() => appContextStore, [appContextStore]);

    return (
        <VersionContext.Provider value={memoVersionContextStore}>
            <AppContext.Provider value={memoAppContextStore}>
                <Header setSearchResults={setSearchResults} />
                <div className="map-container">
                    <SearchResults searchResults={searchResults} />
                    <FeatureViewer />
                    <MapControl />
                    {measurementToolOpen && <MeasurementControl />}
                    {createObjectOpen && <CreateObjectControl />}
                </div>
            </AppContext.Provider>
        </VersionContext.Provider>
    );
}
