import React, { Children } from "react";
import "./TabItems.css";

interface TabItemsProps {
    children: React.ReactNode;
    activeItem?: number;
    setActiveItem?: (index: number) => void;
}

const TabItems = (props: TabItemsProps) => {
    const { children, activeItem, setActiveItem } = props;

    return (
        <div className="tab-items">
            {Children.map(children, (child, index) => {
                return React.cloneElement(child as React.ReactElement, {
                    active: activeItem === index,
                    onClick: () => setActiveItem && setActiveItem(index),
                });
            })}
        </div>
    );
};

export default TabItems;
