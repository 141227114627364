import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import Polyline from "@arcgis/core/geometry/Polyline";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";

export enum SectorColor {
    GREEN = "#00FF0090",
    YELLOW = "#FFFF0090",
    RED = "#FF000090",
    BLANK = "#00000000",
}

export interface Sector {
    color?: SectorColor;
    startAngle?: number;
    outerRadius?: number;
    startLineLength?: number;
    endLineLength?: number;
    index: number;
}

export class DefaultSectorValues {
    static readonly defaultOuterRadius = 600;
    static readonly defaultLineLength = 1000;
}

export const drawSectors = (graphicsLayer: GraphicsLayer, sectors: Sector[], position: Point) => {
    const center = { x: position.x, y: position.y };
    let previousEndAngle = 0;
    const firstAngle = sectors[0].startAngle ?? 0;

    sectors.forEach((sector, index) => {
        const startAngle = sector.startAngle ?? previousEndAngle;
        const endAngle =
            index < sectors.length - 1 ? sectors[index + 1].startAngle ?? 360 + firstAngle : 360 + firstAngle;
        const outerRadius = sector.outerRadius ?? DefaultSectorValues.defaultOuterRadius;
        const innerRadius = outerRadius - 75; // in meters

        if (sector.color) {
            const sectorGraphic = createSector({
                center,
                innerRadius,
                outerRadius,
                startAngle,
                endAngle,
                color: sector.color,
                spatialReference: position.spatialReference,
                index,
            });
            graphicsLayer.add(sectorGraphic);
        }

        previousEndAngle = endAngle;
    });

    // Draw all lines after, i.e. on top of, sectors
    sectors.forEach((sector, index) => {
        const lineEndIndex = index < sectors.length - 1 ? index + 1 : 0;
        const startLineLength = sector.startLineLength ?? DefaultSectorValues.defaultLineLength;
        const endLineLength =
            sector.endLineLength ?? sectors[lineEndIndex].startLineLength ?? DefaultSectorValues.defaultLineLength;

        // Draw lines between sectors
        if (index < sectors.length) {
            const lineStartAngle = sectors[index].startAngle ?? 0;
            const lineEndAngle = sectors[lineEndIndex].startAngle ?? 0;
            const lineGraphicStart = createLine(
                center,
                lineStartAngle,
                startLineLength,
                position.spatialReference,
                index
            );
            const lineGraphicEnd = createLine(
                center,
                lineEndAngle,
                endLineLength,
                position.spatialReference,
                lineEndIndex
            );
            graphicsLayer.add(lineGraphicStart);
            graphicsLayer.add(lineGraphicEnd);
        }
    });

    const centerGraphic = new Graphic({
        geometry: position,
        attributes: { objectType: "center" },
        symbol: new SimpleMarkerSymbol({
            style: "circle",
            color: [0, 0, 0, 1],
            size: 8,
        }),
    });
    graphicsLayer.add(centerGraphic);
};

interface SectorParams {
    center: { x: number; y: number };
    innerRadius: number;
    outerRadius: number;
    startAngle: number;
    endAngle: number;
    color: SectorColor;
    spatialReference: SpatialReference;
    index: number;
}

function createSector({
    center,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    color,
    spatialReference,
    index,
}: SectorParams) {
    const points = [];
    const numPoints = 100;
    const angleStep = (endAngle - startAngle) / numPoints;

    // Outer arc
    for (let i = 0; i <= numPoints; i++) {
        const angle = startAngle + i * angleStep;
        const x = center.x + outerRadius * Math.cos((angle * Math.PI) / 180);
        const y = center.y + outerRadius * Math.sin((angle * Math.PI) / 180);
        points.push([x, y]);
    }

    // Inner arc (reverse direction)
    for (let i = numPoints; i >= 0; i--) {
        const angle = startAngle + i * angleStep;
        const x = center.x + innerRadius * Math.cos((angle * Math.PI) / 180);
        const y = center.y + innerRadius * Math.sin((angle * Math.PI) / 180);
        points.push([x, y]);
    }

    const polygon = new Polygon({
        rings: [points],
        spatialReference: spatialReference,
    });

    const fillSymbol = new SimpleFillSymbol({
        color: color,
        outline: {
            color: [255, 255, 255, 0],
            width: 1,
        },
    });

    return new Graphic({
        geometry: polygon,
        symbol: fillSymbol,
        attributes: {
            objectType: "sector",
            index: index,
        },
    });
}

function createLine(
    center: { x: number; y: number },
    angle: number,
    length: number,
    spatialReference: SpatialReference,
    index: number
) {
    const x = center.x + length * Math.cos((angle * Math.PI) / 180);
    const y = center.y + length * Math.sin((angle * Math.PI) / 180);

    const polyline = new Polyline({
        paths: [
            [
                [center.x, center.y],
                [x, y],
            ],
        ],
        spatialReference: spatialReference,
    });

    const lineSymbol = new SimpleLineSymbol({
        color: [0, 0, 0, 1],
        width: 1,
    });

    const lineGraphic = new Graphic({
        geometry: polyline,
        symbol: lineSymbol,
        attributes: {
            objectType: "line",
            index: index,
        },
    });

    return lineGraphic;
}
