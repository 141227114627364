import Graphic from "@arcgis/core/Graphic";
import { AppConfig } from "../../../AppConfig";
import ObjectTypeButton from "./ObjectTypeButton";

interface LatestObjectTypesPanelProps {
    setActiveLayerId: (layerId: string) => void;
    setEditMode: (editMode: boolean) => void;
    setNewFeature: (feature: Graphic) => void;
}

const LatestObjectTypesPanel = (props: LatestObjectTypesPanelProps) => {
    const { setActiveLayerId, setEditMode, setNewFeature } = props;

    const latestObjectTypes = JSON.parse(localStorage.getItem("latestObjectTypes") ?? "[]") as string[];

    const noObjects = <span className="center create-object-group">Ingen objekter å vise.</span>;

    if (!latestObjectTypes || latestObjectTypes.length === 0) {
        return noObjects;
    }

    return (
        <div className="flex-col create-object-group">
            {latestObjectTypes.map((configLayerName) => {
                const objectGroupConfig = AppConfig.CreateObject.flatMap(
                    (objectGroupConfig) => objectGroupConfig.GroupEntries
                ).find((groupEntryConfig) => groupEntryConfig.Title === configLayerName);

                if (!objectGroupConfig) return null;
                return (
                    <ObjectTypeButton
                        key={objectGroupConfig.Title}
                        layerName={objectGroupConfig.LayerName}
                        title={objectGroupConfig.Title}
                        presetAttributes={objectGroupConfig.FieldValues}
                        setActiveLayerId={setActiveLayerId}
                        setEditMode={setEditMode}
                        setNewFeature={setNewFeature}
                    />
                );
            })}
        </div>
    );
};

export default LatestObjectTypesPanel;
