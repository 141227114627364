import React, { Children } from "react";

interface TabPanelsProps {
    children: React.ReactNode;
    activeItem?: number;
}

const TabPanels = (props: TabPanelsProps) => {
    const { children, activeItem } = props;

    return Children.map(children, (child, index) => {
        return React.cloneElement(child as React.ReactElement, {
            active: activeItem === index,
        });
    });
};

export default TabPanels;
