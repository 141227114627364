import { useDispatch, useSelector } from "react-redux";
import { AppConfig } from "../../AppConfig";
import { StoreState } from "../../store/rootReducer";
import { removeUserDefinedScale } from "../../store/scaleSlice";
import { formatScaleString } from "../../utils/helpers";
import NfsButton from "../genericComponents/NfsButton/NfsButton";
import TrashCanIcon from "../../assets/trash-can.svg?react";

interface RemoveScaleProps {
    selectedOption: number | null;
    setScale: (option: string | null) => void;
    allScales: number[];
}

const RemoveScale = (props: RemoveScaleProps) => {
    const { selectedOption, setScale, allScales } = props;

    const userDefinedScales = useSelector((state: StoreState) => state.scale.userDefinedScales);
    const dispatch = useDispatch();

    return (
        <NfsButton
            onClick={() => {
                dispatch(removeUserDefinedScale(selectedOption));
                localStorage.setItem(
                    "userDefinedScales",
                    JSON.stringify(userDefinedScales.filter((scale) => scale !== selectedOption))
                );
                const currentScaleIndex = allScales.indexOf(selectedOption ?? AppConfig.Scale.DefaultScale);
                setScale(
                    currentScaleIndex === 0
                        ? formatScaleString(allScales[currentScaleIndex + 1])
                        : formatScaleString(allScales[currentScaleIndex - 1])
                );
            }}
        >
            <TrashCanIcon />
        </NfsButton>
    );
};

export default RemoveScale;
