import Graphic from "@arcgis/core/Graphic";
import { AppConfig } from "../../../AppConfig";
import ObjectTypeButton from "./ObjectTypeButton";
import ObjectGroup from "./ObjectGroup";

interface AllObjectTypesPanelProps {
    setActiveLayerId: (layerId: string) => void;
    setEditMode: (editMode: boolean) => void;
    setNewFeature: (feature: Graphic) => void;
}

const AllObjectTypesPanel = (props: AllObjectTypesPanelProps) => {
    const { setActiveLayerId, setEditMode, setNewFeature } = props;

    return (
        <div className="flex-col medium-gap all-objects scrollable">
            {AppConfig.CreateObject.map((objectGroupConfig) => {
                return (
                    <ObjectGroup title={objectGroupConfig.GroupName} key={objectGroupConfig.GroupName}>
                        {objectGroupConfig.GroupEntries.map((groupEntryConfig) => {
                            return (
                                <ObjectTypeButton
                                    key={groupEntryConfig.Title}
                                    title={groupEntryConfig.Title}
                                    layerName={groupEntryConfig.LayerName}
                                    presetAttributes={groupEntryConfig.FieldValues}
                                    setActiveLayerId={setActiveLayerId}
                                    setEditMode={setEditMode}
                                    setNewFeature={setNewFeature}
                                />
                            );
                        })}
                    </ObjectGroup>
                );
            })}
        </div>
    );
};

export default AllObjectTypesPanel;
