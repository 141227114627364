import { Sector } from "./utils/lighthouseUtils";

export interface ArcGISConfig {
    BaseUrl: string;
    PortalUrl: string;
    AppClientId: string;
    AppRedirectUrl: string;
    SyncDB: string;
    VersionManagementService: string;
}

export interface NFSAPIConfig {
    BaseApiUrl: string;
}

export interface FDVConfig {
    BaseUrl: string;
    ObjectPropertiesBase: string;
}

export interface BasemapConfig {
    Name: string;
    ServiceUrl: string;
    ThumbnailUri: string;
}

export interface AppConfigInterface {
    Environment: string;
    Version: string;
    NFSAPI: NFSAPIConfig;
    FDV: FDVConfig;
    ArcGIS: ArcGISConfig;
    Basemaps: BasemapConfig[];
    Layers: LayersConfig[];
    Scale: ScalesConfig;
    Projection: ProjectionConfig;
    CreateObject: CreateObjectGroupConfig[];
    Lighthouse: LighthouseConfig;
}

export const AppConfig: AppConfigInterface = {
    ...window.NFSLAND_CONFIG,
};

export interface LayersConfig {
    Name: string;
    Url: string;
    zoomScale: number;
    SearchFields: string[];
    TitleField: string;
    ImportantFields: string[];
    HighlightedFields: string[];
    StatusField: string;
    ExcludeFields: string[];
    IsTable: boolean;
    Type: string;
    Order: number;
    DefaultVisible: boolean;
}

export interface ScalesConfig {
    Scales: number[];
    DefaultScale: number;
    MinScale: number;
    MaxScale: number;
}

export interface ProjectionConfig {
    CenterMeridian: number;
}

export interface CreateObjectDefaultValuesConfig {
    FieldName: string;
    Value: any;
}

export interface CreateObjectConfig {
    Title: string;
    LayerName: string; // This should be the same as LayerConfig.Name
    FieldValues: CreateObjectDefaultValuesConfig[];
}

export interface CreateObjectGroupConfig {
    GroupName: string;
    GroupEntries: CreateObjectConfig[];
}

export interface LighthouseConfig {
    DefaultSectors: Sector[];
}
