import "./TabItem.css";

interface TabItemProps {
    label: string;
    active?: boolean;
    onClick?: () => void;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const TabItem = (props: TabItemProps) => {
    const { label, active, onClick, Icon } = props;

    return (
        <div>
            <button onClick={() => onClick?.()} className={`tab-item ${active ? "active" : ""}`}>
                <Icon />
                <span>{label}</span>
            </button>
        </div>
    );
};

export default TabItem;
