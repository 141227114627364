// import React from "react";
// import { VersionContext } from "../../Context";
import { UserAccount } from "../UserAccount/UserAccount";
import "./Header.css";
import DarkModeToggle from "./DarkModeToggle";
import Search from "./Search";
import logo from "../../assets/tempLogo.png";

interface HeaderProps {
  setSearchResults: (results: __esri.SearchViewModelSearchResponseResults[]) => void;
}

const Header = (props: HeaderProps) => {
  // const versionContext = React.useContext(VersionContext);

  // const toggleVersionManagement = () => {
  //   versionContext?.showVersionMgmt.set(!versionContext.showVersionMgmt.value);
  // };

  return (
    <div className="header-container">
      <div className="header-logo">
        <img src={logo} alt="Nautisk Fagsystem" />
        <span className="header-title">
          NAUTISK FAGSYSTEM
        </span>
      </div>
      <div className="header-menu">
        {/* Version: {AppConfig.Version} - {AppConfig.Environment} */}
        {/* <button onClick={toggleVersionManagement}>Toggle version management</button> */}
        <Search setSearchResults={props.setSearchResults} />
      </div>
      <div className="header-actions">
        <DarkModeToggle />
        <UserAccount />
      </div>
    </div>
  );
}

export default Header;