import { useState } from "react";
import Draggable from "react-draggable";
import CloseIcon from "../../../assets/close.svg?react";
import NfsButton from "../NfsButton/NfsButton";
import RenderInWindow from "../../RenderInWindow/RenderInWindow";
import "./FloatingWindow.css";

interface FloatingWindowProps {
    toolRef: React.RefObject<HTMLDivElement>;
    closeWindow: () => void;
    children: React.ReactNode;
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    title?: string;
    className?: string;
}

const FloatingWindow = (props: FloatingWindowProps) => {
    const { toolRef, closeWindow, children, Icon, title, className } = props;
    const [popout, setPopout] = useState<boolean>(false);

    return (
        <RenderInWindow disabled={!popout} onClose={closeWindow}>
            <Draggable disabled={popout} bounds=".map-container" nodeRef={toolRef} handle=".floating-window-header">
                <div ref={toolRef} className={`floating-window-container ${className ?? ""}`}>
                    <div className="floating-window-header">
                        <div className="floating-window-header-info">
                            {Icon && <Icon />}
                            <span>{title ?? ""}</span>
                        </div>
                        <NfsButton className="empty floating-window-close" onClick={closeWindow}>
                            <CloseIcon />
                        </NfsButton>
                    </div>
                    <div className="floating-window-content">{children}</div>
                    <button
                        className="empty-button"
                        style={{ cursor: "default", width: "40px" }}
                        type="button"
                        title="Hidden"
                        onDoubleClick={() => setPopout(!popout)}
                    />
                </div>
            </Draggable>
        </RenderInWindow>
    );
};

export default FloatingWindow;
