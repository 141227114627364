import React, { Children, ReactElement } from "react";
import "./Tabs.css";

interface TabProps {
    children: React.ReactNode;
}

const Tabs = (props: TabProps) => {
    const { children } = props;
    const [activeItem, setActiveItem] = React.useState(0);

    return (
        <div className="tabs">
            {Children.map(children, (child) => {
                return React.cloneElement(child as ReactElement, {
                    activeItem: activeItem,
                    setActiveItem: setActiveItem,
                });
            })}
        </div>
    );
};

export default Tabs;
