import Circle from "@arcgis/core/geometry/Circle";
import Point from "@arcgis/core/geometry/Point";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import MapView from "@arcgis/core/views/MapView";
import { clamp } from "../../../utils/helpers";
import { DefaultSectorValues, drawSectors, Sector } from "../../../utils/lighthouseUtils";

export const lighthouseHandlerPointerMove = (
    view: MapView,
    currentLine: React.MutableRefObject<Graphic | null>,
    isDragging: React.MutableRefObject<boolean>,
    isCenterSelected: React.MutableRefObject<boolean>
) => {
    const lighthouseLayer = view.map.layers.find((layer) => layer.id === "lighthouseLayer");

    return view.on("pointer-move", (event) => {
        // Refresh overlay graphics
        view.graphics.removeAll();

        view.hitTest(event, { include: lighthouseLayer }).then((response) => {
            handleHitTestResponse(view, response, currentLine, isDragging, isCenterSelected);
        });
    });
};

const handleHitTestResponse = (
    view: MapView,
    response: __esri.HitTestResult,
    currentLine: React.MutableRefObject<Graphic | null>,
    isDragging: React.MutableRefObject<boolean>,
    isCenterSelected: React.MutableRefObject<boolean>
) => {
    const results = response.results;
    const currentResult = (results.find(
        (result) => (result as __esri.GraphicHit).graphic.attributes.index === currentLine.current?.attributes.index
    ) ?? results[0]) as __esri.GraphicHit;

    if (!isCenterSelected.current) {
        updateCurrentLine(currentResult, currentLine, isDragging);
        addOverlayGraphics(view, currentResult);
    }

    if (view.container.style.cursor !== "move") {
        updateCursorStyle(view, currentResult);
    }
};

const updateCurrentLine = (
    firstResult: __esri.GraphicHit,
    currentLine: React.MutableRefObject<Graphic | null>,
    isDragging: React.MutableRefObject<boolean>
) => {
    if (firstResult && firstResult.graphic.geometry.type === "polyline" && !isDragging.current) {
        currentLine.current = firstResult?.graphic ?? null;
    } else if (!isDragging.current) {
        currentLine.current = null;
    }
};

const addOverlayGraphics = (view: MapView, firstResult: __esri.GraphicHit) => {
    if (firstResult && firstResult.graphic.attributes.objectType === "line") {
        const newGraphic = firstResult.graphic.clone();

        const dragHandleGraphic = new Graphic({
            geometry: new Circle({
                center: new Point({
                    x: (newGraphic.geometry as Polyline).paths[0][1][0],
                    y: (newGraphic.geometry as Polyline).paths[0][1][1],
                    spatialReference: view.spatialReference,
                }),
                radius: 10,
                spatialReference: view.spatialReference,
                geodesic: true,
            }),
            symbol: new SimpleMarkerSymbol({
                style: "circle",
                color: [0, 0, 0, 1],
                size: 10,
            }),
            attributes: {
                objectType: "dragHandle",
            },
        });
        (newGraphic.symbol as SimpleLineSymbol).width = 3;
        view.graphics.add(newGraphic);
        view.graphics.add(dragHandleGraphic);
    }
};

const updateCursorStyle = (view: MapView, currentResult: __esri.GraphicHit) => {
    if (currentResult) {
        if (currentResult.graphic?.geometry.type !== "polyline") return;

        const angleDeg = calculateAngle(currentResult.graphic.geometry as Polyline);
        setCursorStyle(view, angleDeg);
    } else {
        view.container.style.cursor = "default";
    }
};

const calculateAngle = (geometry: Polyline): number => {
    const lineCoords = geometry.paths[0];
    const angle = Math.atan2(lineCoords[1][1] - lineCoords[0][1], lineCoords[1][0] - lineCoords[0][0]);
    return ((angle * 180) / Math.PI + 360) % 360;
};

const setCursorStyle = (view: MapView, angleDeg: number) => {
    if (angleDeg <= 22.5 || angleDeg > 337.5 || (angleDeg > 157.5 && angleDeg <= 202.5)) {
        view.container.style.cursor = "ns-resize";
    } else if ((angleDeg > 22.5 && angleDeg <= 67.5) || (angleDeg > 202.5 && angleDeg <= 247.5)) {
        view.container.style.cursor = "nwse-resize";
    } else if ((angleDeg > 67.5 && angleDeg <= 112.5) || (angleDeg > 247.5 && angleDeg <= 292.5)) {
        view.container.style.cursor = "ew-resize";
    } else if ((angleDeg > 112.5 && angleDeg <= 157.5) || (angleDeg > 292.5 && angleDeg <= 337.5)) {
        view.container.style.cursor = "nesw-resize";
    }
};

export const lighthouseHandlerPointerMoveDragHandle = (view: MapView) => {
    return view.on("pointer-move", (event) => {
        view.hitTest(event, { include: view.graphics }).then((response) => {
            const results = response.results.filter(
                (result) => (result as __esri.GraphicHit).graphic.attributes.objectType === "dragHandle"
            );
            if (results.length > 0) {
                view.container.style.cursor = "move";
            } else {
                view.container.style.cursor = "default";
            }
        });
    });
};

export const lighthouseHandlerPointerMoveCenter = (
    view: MapView,
    isCenterSelected: React.MutableRefObject<boolean>,
    isDraggingLine: React.MutableRefObject<boolean>
) => {
    const lighthouseLayer = view.map.layers.find((layer) => layer.id === "lighthouseLayer");

    return view.on("pointer-move", (event) => {
        view.hitTest(event, { include: lighthouseLayer }).then((response) => {
            const results = response.results.filter(
                (result) => (result as __esri.GraphicHit).graphic.attributes.objectType === "center"
            );
            if (results.length > 0 && !isDraggingLine.current) {
                view.container.style.cursor = "move";
                isCenterSelected.current = true;
                view.graphics.removeAll();
            } else {
                isCenterSelected.current = false;
            }
        });
    });
};

const dragStartHandler = (
    view: MapView,
    dragStartCursor: React.MutableRefObject<string>,
    currentLine: React.MutableRefObject<Graphic | null>,
    temporaryLine: React.MutableRefObject<Graphic | null>
) => {
    dragStartCursor.current = view.container.style.cursor;

    if (!currentLine.current) return;

    // Temporary line to show where the line currently being dragged was before starting dragging
    temporaryLine.current = new Graphic({
        geometry: currentLine.current.geometry,
        attributes: { objectType: "temporaryLine" },
        symbol: new SimpleLineSymbol({
            color: [0, 0, 0, 1],
            width: 1,
            style: "dash",
        }),
    });
};

interface DragEndHandlerParams {
    view: MapView;
    lighthouseLayer: GraphicsLayer;
    data: Sector[] | null;
    currentLine: React.MutableRefObject<Graphic | null>;
    temporaryLine: React.MutableRefObject<Graphic | null>;
    lighthouseData: React.MutableRefObject<Sector[] | null>;
    isDragging: React.MutableRefObject<boolean>;
    isCenterSelected: React.MutableRefObject<boolean>;
}

const dragEndHandler = ({
    view,
    lighthouseLayer,
    data,
    currentLine,
    temporaryLine,
    lighthouseData,
    isDragging,
    isCenterSelected,
}: DragEndHandlerParams) => {
    const lighthouseLayerView = view.layerViews.find(
        (layerView) => layerView.layer.id === "lighthouseLayer"
    ) as __esri.GraphicsLayerView;

    lighthouseLayerView.queryGraphics().then((graphics) => {
        data?.forEach((sector, index) => {
            const sectorLine = graphics.find((graphic) => {
                return graphic.attributes.index === sector.index && graphic.attributes.objectType === "line";
            });
            if (!sectorLine) return;

            const newSectorLine = sectorLine.clone();
            newSectorLine.attributes.index = sector.index;
            lighthouseLayer.remove(sectorLine);
            lighthouseLayer.add(newSectorLine);

            sector.index = index;
        });
    });

    currentLine.current = null;
    lighthouseData.current = data;
    isDragging.current = false;
    isCenterSelected.current = false;

    if (temporaryLine.current) {
        lighthouseLayer.remove(temporaryLine.current);
        temporaryLine.current = null;
    }
};

interface DragHandlerParams {
    view: MapView;
    currentLine: React.MutableRefObject<Graphic | null>;
    temporaryLine: React.MutableRefObject<Graphic | null>;
    dragStartCursor: React.MutableRefObject<string>;
    lighthouseData: React.MutableRefObject<Sector[] | null>;
    isDraggingLine: React.MutableRefObject<boolean>;
    isDraggingCenter: React.MutableRefObject<boolean>;
    isCenterSelected: React.MutableRefObject<boolean>;
    lighthouseCenter: React.MutableRefObject<Point | null>;
}

const limitLineLength = (
    dragStartCursor: React.MutableRefObject<string>,
    adjacentLineLength1: number,
    adjacentLineLength2: number,
    newLineLength: number,
    currentItem: Sector | undefined
) => {
    if (dragStartCursor.current === "move") {
        // The new line length should be at least as long as the adjacent outer sectors
        if (
            adjacentLineLength1 &&
            adjacentLineLength2 &&
            newLineLength < Math.max(adjacentLineLength1, adjacentLineLength2)
        ) {
            newLineLength = Math.max(adjacentLineLength1, adjacentLineLength2);
        }
        currentItem!.startLineLength = newLineLength;
    }
};

const dragLogic = (event: __esri.ViewDragEvent, params: DragHandlerParams) => {
    const {
        view,
        currentLine,
        temporaryLine,
        dragStartCursor,
        lighthouseData,
        isDraggingLine,
        isDraggingCenter,
        isCenterSelected,
        lighthouseCenter,
    } = params;

    event.stopPropagation();

    const lighthouseLayer = view.map.layers.find((layer) => layer.id === "lighthouseLayer") as GraphicsLayer;

    const data = lighthouseData.current;

    // Stop dragging in the map if we are dragging a drag handle
    const action = event.action;

    // Remove all graphics from the lighthouse test layer.
    // We want to draw the sectors again with the new line lengths and angles
    lighthouseLayer.graphics.removeAll();

    if ((isCenterSelected.current || isDraggingCenter.current) && !isDraggingLine.current) {
        lighthouseCenter.current = view.toMap({ x: event.x, y: event.y });
        isDraggingCenter.current = true;
    } else if (currentLine.current && !isCenterSelected.current && !isDraggingCenter.current) {
        isDraggingLine.current = true;
        if (action === "start") {
            dragStartHandler(view, dragStartCursor, currentLine, temporaryLine);
        }

        if (temporaryLine.current) lighthouseLayer.add(temporaryLine.current);

        // Compute the new line length based on cursor position
        const endPoint = view.toMap({ x: event.x, y: event.y });
        const geometry = currentLine.current.geometry as Polyline;

        let newLineLength = Math.sqrt(
            Math.pow(endPoint.x - geometry.paths[0][0][0], 2) + Math.pow(endPoint.y - geometry.paths[0][0][1], 2)
        );

        const prevIndex = clamp(currentLine.current.attributes.index - 1, 0, data!.length - 1);
        const currentIndex = currentLine.current.attributes.index;
        const prevItem = data!.find((sector) => sector.index === prevIndex);
        const currentItem = data!.find((sector) => sector.index === currentIndex);

        const adjacentLineLength1 = prevItem!.outerRadius ?? DefaultSectorValues.defaultOuterRadius;
        const adjacentLineLength2 = currentItem!.outerRadius ?? DefaultSectorValues.defaultOuterRadius;

        limitLineLength(dragStartCursor, adjacentLineLength1, adjacentLineLength2, newLineLength, currentItem);

        const newAngle =
            (Math.atan2(endPoint.y - geometry.paths[0][0][1], endPoint.x - geometry.paths[0][0][0]) * 180) / Math.PI;
        const newAngleCircleLimited = (newAngle + 360) % 360;

        // Make sure the angle is between 0 and 360,
        // except for the first sector which can be negative.
        // This ensures that the sectors are drawn correctly
        currentItem!.startAngle = newAngleCircleLimited;

        // Sort the elements in the table. This prevents overlapping sectors,
        // and also drawing errors when dragging sectors past the 0/360 degree mark
        data?.sort((a, b) => a.startAngle! - b.startAngle!);

        if (action === "end") {
            dragEndHandler({
                view: view,
                lighthouseLayer: lighthouseLayer,
                data: data,
                currentLine: currentLine,
                temporaryLine: temporaryLine,
                lighthouseData: lighthouseData,
                isDragging: isDraggingLine,
                isCenterSelected: isCenterSelected,
            });
        }
    }

    drawSectors(
        lighthouseLayer,
        data as Sector[],
        new Point({
            x: lighthouseCenter.current?.x,
            y: lighthouseCenter.current?.y,
            spatialReference: view.spatialReference,
        })
    );
};

export const lighthouseHandlerDrag = ({
    view,
    currentLine,
    temporaryLine,
    dragStartCursor,
    lighthouseData,
    isDraggingLine,
    isDraggingCenter,
    isCenterSelected,
    lighthouseCenter,
}: DragHandlerParams) => {
    return view.on("drag", (event) => {
        if (currentLine.current || isCenterSelected.current || isDraggingCenter.current || isDraggingLine.current) {
            dragLogic(event, {
                view: view,
                currentLine: currentLine,
                temporaryLine: temporaryLine,
                dragStartCursor: dragStartCursor,
                lighthouseData: lighthouseData,
                isDraggingLine: isDraggingLine,
                isDraggingCenter: isDraggingCenter,
                isCenterSelected: isCenterSelected,
                lighthouseCenter: lighthouseCenter,
            });
        } else {
            isDraggingLine.current = false;
            isDraggingCenter.current = false;
            isCenterSelected.current = false;
        }

        if (event.action === "end") {
            isDraggingLine.current = false;
            isDraggingCenter.current = false;
            currentLine.current = null;
            isCenterSelected.current = false;
        }
    });
};
