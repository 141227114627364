import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import * as arcade from "@arcgis/core/arcade.js";

export const GetCodedValueAndAlias = (layer: any, fieldName: string, fieldValue: number | string) => {
    let returnValue = [fieldName, fieldValue];
    if (!layer?.fields) return returnValue;
    layer.fields.forEach((fld: any) => {
        if (fld.name === fieldName) {
            returnValue[0] = fld?.alias;
            const cDomain = fld?.domain;
            if (cDomain)
                cDomain.codedValues.forEach((cVal: any) => {
                    if (cVal.code === fieldValue) returnValue[1] = cVal.name;
                });
        }
    });
    return returnValue;
};

export const IsFeatureInMapExtent = (feature: __esri.Graphic) => {
    const map = document.getElementsByTagName("arcgis-map")[0];
    if (!map) return false;

    const inExtent = geometryEngine.contains(map.extent, feature.geometry);

    return inExtent;
};

export const getMapView = () => {
    const map = document.getElementsByTagName("arcgis-map")[0];
    if (!map) {
        return;
    }
    const mapview = map.view;
    if (!mapview) {
        return;
    }
    return mapview;
};

export const highlightFeature = async (feature: __esri.Graphic): Promise<IHandle | void> => {
    const map = document.getElementsByTagName("arcgis-map")[0];
    if (!map) return;
    if (feature?.layer instanceof FeatureLayer) {
        return await map.view.whenLayerView(feature.layer).then((layerView) => {
            return layerView.highlight(feature);
        });
    }
};

export const setFeatureAttribute = (feature: Graphic, attributeName: string, attributeValue: any) => {
    const keys = Object.keys(feature.attributes);
    if (keys.includes(attributeName)) {
        feature.attributes[attributeName] = attributeValue;
    }
};

export const updateFeatureAttributes = async (updatedFeature: __esri.Graphic): Promise<boolean> => {
    const success = false;
    const result = await (updatedFeature.layer as FeatureLayer).applyEdits({ updateFeatures: [updatedFeature] });

    if (result.updateFeatureResults[0].error) {
        console.error("Error updating feature: ", result.updateFeatureResults[0].error);
    } else {
        console.log("Feature updated successfully");
        return true;
    }

    return success;
};

/* export const getPlaceNameFromPoint = (point: Geometry) => {
    getAGEToken().then((token) => {
        console.log(token);
        const geocodingServiceUrl = "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer";
        const params = {
            location: point as Point,
        };

        locator.locationToAddress(geocodingServiceUrl, params).then((response) => {
            console.log(response);
        });
    });
}; */

export const evaluateArcadeExpression = async (expression: string, feature: Graphic | null) => {
    if (!feature) return;

    const profile = {
        variables: [
            {
                name: "$feature",
                type: "feature",
            } as __esri.ProfileVariable,
        ],
    };

    const executor = await arcade.createArcadeExecutor(expression, profile);
    return executor.execute({ $feature: feature });
};
