import { uuidv4 } from "./helpers";
import { AppConfig } from "../AppConfig";
import { getAGEToken } from "./authenticate";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

export const getSessionID = () => {
    return '{' + uuidv4() + '}';
};

export const getDiffrences = async (versionGuid: string, sessionID: string, resultType?: string): Promise<DiffrencesObject[]> => {
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    let features: DiffrencesObject[] = [];
    resultType = resultType ?? 'features';
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(versionServiceUrl + `/versions/${versionGuid}/differences?f=json&sessionId=${urlSessionID}&resultType=${resultType}`,
        {
            method: 'POST',
            headers: {
                'X-Esri-Authorization': 'Bearer ' + token
            }
        }).then(async (response) => {
            await response.json().then((data) => {
                if (data && data.features) {
                    features = data.features
                }
            });
        }).catch((error) => {
            console.error(error);
        });
    return features;
}

export const reconcileVersion = async (versionGuid: string, sessionID: string, abortIfConflict: boolean, conflicDetection: string, withPost: boolean) => {
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(versionServiceUrl + `/versions/${versionGuid}/reconcile?f=json&sessionId=${urlSessionID}&abortIfConflict=${abortIfConflict}&conflictDetection=${conflicDetection}&withPost=${withPost}`,
        {
            method: 'POST',
            headers: {
                'X-Esri-Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            response.json().then((data) => {
                console.log(data);
            });
        }).catch((error) => {
            console.error(error);
        });
};

export const getConflicts = async (versionGuid: string, sessionID: string) => {
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(versionServiceUrl + `/versions/${versionGuid}/conflicts?f=json&sessionId=${urlSessionID}`,
        {
            method: 'POST',
            headers: {
                'X-Esri-Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            response.json().then((data) => {
                console.log(data);
            });
        }).catch((error) => {
            console.error(error);
        });
};

export const startReading = async (versionGuid: string, sessionID: string): Promise<boolean> => {
    const success = false;
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(versionServiceUrl + `/versions/${versionGuid}/startReading?f=json&sessionId=${urlSessionID}`,
        {
            method: 'POST',
            headers: {
                'X-Esri-Authorization': 'Bearer ' + token
            }
        }
    ).then((response) => {
        response.json().then((data) => {
            if (data.success) {
                return true;
            }
        });
    }).catch((error) => {
        console.error(error);
    });
    return success;
};

export const stopReading = async (versionGuid: string, sessionID: string): Promise<boolean> => {
    const success = false;
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(versionServiceUrl + `/versions/${versionGuid}/stopReading?f=json&sessionId=${urlSessionID}`,
        {
            method: 'POST',
            headers: {
                'X-Esri-Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            response.json().then((data) => {
                if (data.success) {
                    return true;
                }
            });
        }).catch((error) => {
            console.error(error);
        });
    return success;
};

export const startEditing = async (versionGuid: string, sessionID: string): Promise<boolean> => {
    const success = false;
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(versionServiceUrl + `/versions/${versionGuid}/startEditing?f=json&sessionId=${urlSessionID}`,
        {
            method: 'POST',
            headers: {
                'X-Esri-Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            response.json().then((data) => {
                if (data.success) {
                    return true;
                }
            });
        }).catch((error) => {
            console.error(error);
        });
    return success;
};

export const stopEditing = async (versionGuid: string, sessionID: string, saveEdits: boolean): Promise<boolean> => {
    const success = false;
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const urlSessionID = encodeURIComponent(sessionID);
    await fetch(versionServiceUrl + `/versions/${versionGuid}/stopEditing?f=json&sessionId=${urlSessionID}&saveEdits=${saveEdits}`,
        {
            method: 'POST',
            headers: {
                'X-Esri-Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            response.json().then((data) => {
                if (data.success) {
                    return true;
                }
            });
        }).catch((error) => {
            console.error(error);
        });
    return success;
};

const getVersionServiceUrl = () => {
    return AppConfig.ArcGIS.BaseUrl + AppConfig.ArcGIS.VersionManagementService;
}

export const findDifferences = async (versionGuid: string): Promise<OrganizedObjects[]> => {
    const sessionID = getSessionID();
    await startReading(versionGuid, sessionID);
    await startEditing(versionGuid, sessionID);
    await reconcileVersion(versionGuid, sessionID, false, "byObject", false);
    const editedFeatures = await getDiffrences(versionGuid, sessionID);
    if (!editedFeatures) {
        await stopEditing(versionGuid, sessionID, false);
        await stopReading(versionGuid, sessionID);
        return [];
    }
    const jsonObjects = await getObjects(editedFeatures);
    await restoreDeleted(4, 8983, versionGuid, jsonObjects, sessionID);
    await stopEditing(versionGuid, sessionID, false);
    await stopReading(versionGuid, sessionID);
    return jsonObjects;
};

interface DiffrencesObject {
    layerId: string,
    inserts: {
        geometry: __esri.Geometry,
        attributes: {
            [key: string]: any
        }
    }[],
    updates: {
        geometry: __esri.Geometry,
        attributes: {
            [key: string]: any
        }
    }[],
    deletes: {
        geometry: __esri.Geometry,
        attributes: {
            [key: string]: any
        }
    }[]
}

interface FeatureObject {
    type: "insert" | "update" | "delete",
    originalFeature?: {
        geometry: __esri.Geometry,
        attributes: {
            [key: string]: any
        }
    },
    originalGraphic?: __esri.Graphic,
    editedFeature: {
        geometry: __esri.Geometry,
        attributes: {
            [key: string]: any
        }
    },
    changedFields: string[]
}

interface OrganizedObjects {
    layerId: string,
    objects: {
        [objectId: string]: FeatureObject
    }
}

const getObjects = async (objectsToFetch: DiffrencesObject[]): Promise<OrganizedObjects[]> => {
    const objects: OrganizedObjects[] = [];
    const promisis: Promise<any>[] = [];

    for (const differences of objectsToFetch) {
        const layerId = differences.layerId;
        const featureLayer = new FeatureLayer({
            url: `https://maps.kystverket.cloudgis.no/enterprise/rest/services/DEV/nfs_dev/FeatureServer/${layerId}`,
        })

        const objectIds = [];
        const insertedFeatureIds: string[] = [];
        const featureMap: Record<string, { type: "insert" | "update" | "delete", feature: any }> = {};
        if (differences.inserts) {
            for (const insert of differences.inserts) {
                insertedFeatureIds.push(insert.attributes.OBJECTID);
                featureMap[insert.attributes.OBJECTID] = {
                    type: "insert",
                    feature: insert
                }
            }
        }
        if (differences.updates) {
            for (const update of differences.updates) {
                objectIds.push(update.attributes.OBJECTID);
                featureMap[update.attributes.OBJECTID] = {
                    type: "update",
                    feature: update
                }
            }
        }
        if (differences.deletes) {
            for (const del of differences.deletes) {
                objectIds.push(del.attributes.OBJECTID);
                featureMap[del.attributes.OBJECTID] = {
                    type: "delete",
                    feature: del
                }
            }
        }

        if (objectIds.length > 0) {
            promisis.push(featureLayer.queryFeatures({
                objectIds: objectIds,
                outFields: ['*'],
                returnGeometry: true,
            }).then((result) => {
                const organizedLayer: OrganizedObjects = {
                    layerId: layerId,
                    objects: {

                    }
                }
                for (const feature of result.features) {
                    const edited = featureMap[feature.attributes.OBJECTID];
                    const changedFields: string[] = [];
                    for (const key in feature.attributes) {
                        if (feature.attributes[key] !== edited.feature.attributes[key]) {
                            changedFields.push(key);
                        }
                    }
                    organizedLayer.objects[feature.attributes.OBJECTID] = {
                        type: edited.type,
                        originalFeature: {
                            geometry: feature.geometry,
                            attributes: feature.attributes
                        },
                        originalGraphic: feature,
                        editedFeature: { geometry: edited.feature.geometry, attributes: edited.feature.attributes },
                        changedFields: changedFields
                    }
                }
                for (const inserted of insertedFeatureIds) {
                    const edited = featureMap[inserted];
                    organizedLayer.objects[inserted] = {
                        type: edited.type,
                        originalFeature: undefined,
                        originalGraphic: undefined,
                        editedFeature: { geometry: edited.feature.geometry, attributes: edited.feature.attributes },
                        changedFields: Object.keys(edited.feature.attributes)
                    }
                }

                objects.push(organizedLayer);
            }).catch((error) => {
                console.error(error);
            }));
        } else {
            const organizedLayer: OrganizedObjects = {
                layerId: layerId,
                objects: {

                }
            }

            for (const inserted of insertedFeatureIds) {
                const edited = featureMap[inserted];
                organizedLayer.objects[inserted] = {
                    type: edited.type,
                    originalFeature: undefined,
                    originalGraphic: undefined,
                    editedFeature: { geometry: edited.feature.geometry, attributes: edited.feature.attributes },
                    changedFields: Object.keys(edited.feature.attributes)
                }
            }
            objects.push(organizedLayer);
        }
    }

    await Promise.all(promisis);

    return objects;
};

export const restoreAttributes = async (attributes: string[], feature: __esri.Graphic, versionChanges: OrganizedObjects[]): Promise<__esri.Graphic> => {
    const newFeature = feature.clone();
    const layerId = (feature.layer as FeatureLayer).layerId.toString();

    const versionChange = versionChanges.find((change) => {
        return change.layerId == layerId
    });

    if (!versionChange) {
        return feature;
    }

    const originalFeature = versionChange.objects[newFeature.attributes.OBJECTID].originalFeature;
    if (!originalFeature) {
        return feature;
    }

    for (const attribute of attributes) {
        if (newFeature.attributes.hasOwnProperty(attribute)) {
            newFeature.attributes[attribute] = originalFeature.attributes[attribute];
        }
    }

    const result = await (feature.layer as FeatureLayer).applyEdits({ updateFeatures: [feature] });
    if (result.updateFeatureResults[0].error) {
        console.error("Error updating feature: ", result.updateFeatureResults[0].error);
    } else {
        return newFeature;
    }

    return feature;
};

export const restoreGeometry = async (feature: __esri.Graphic, versionChanges: OrganizedObjects[]): Promise<__esri.Graphic> => {
    const newFeature = feature.clone();
    const layerId = (feature.layer as FeatureLayer).layerId.toString();

    const versionChange = versionChanges.find((change) => {
        return change.layerId == layerId
    });

    if (!versionChange) {
        return feature;
    }

    const originalFeature = versionChange.objects[newFeature.attributes.OBJECTID].originalFeature;
    if (!originalFeature) {
        return feature;
    }

    newFeature.geometry = originalFeature.geometry;

    const result = await (feature.layer as FeatureLayer).applyEdits({ updateFeatures: [feature] });
    if (result.updateFeatureResults[0].error) {
        console.error("Error updating feature: ", result.updateFeatureResults[0].error);
    } else {
        return newFeature;
    }

    return feature;
};

export const restoreAll = async (feature: __esri.Graphic, versionChanges: OrganizedObjects[]): Promise<__esri.Graphic> => {
    const newFeature = feature.clone();
    const layerId = (feature.layer as FeatureLayer).layerId.toString();

    const versionChange = versionChanges.find((change) => {
        return change.layerId == layerId
    });

    if (!versionChange) {
        return feature;
    }

    const originalFeature = versionChange.objects[newFeature.attributes.OBJECTID].originalFeature;
    if (!originalFeature) {
        return feature;
    }

    newFeature.geometry = originalFeature.geometry;
    for (const key in newFeature.attributes) {
        newFeature.attributes[key] = originalFeature.attributes[key];
    }

    const result = await (feature.layer as FeatureLayer).applyEdits({ updateFeatures: [feature] });
    if (result.updateFeatureResults[0].error) {
        console.error("Error updating feature: ", result.updateFeatureResults[0].error);
    } else {
        return newFeature;
    }

    return feature;
}

export const removeInserted = async (feature: __esri.Graphic, versionChanges: OrganizedObjects[]): Promise<boolean> => {
    const layerId = (feature.layer as FeatureLayer).layerId.toString();

    const versionChange = versionChanges.find((change) => {
        return change.layerId == layerId
    });

    if (!versionChange) {
        return false;
    }

    const featureChange = versionChange.objects[feature.attributes.OBJECTID];
    if (featureChange.type == "insert") {
        const result = await (feature.layer as FeatureLayer).applyEdits({ deleteFeatures: [feature] });
        if (result.deleteFeatureResults[0].error) {
            console.error("Error deleting feature: ", result.deleteFeatureResults[0].error);
            return false;
        } else {
            return true;
        }
    }

    return false;
};

// // To do replace layerid and objectid with featureobject type
// // To do investigate the possibility of restoring the object identically to the original
// /**
//  * This does not work properly as the restored object is not identical to the original
//  * @param layerId 
//  * @param objectId 
//  * @param versionName 
//  * @param versionChanges 
//  * @returns 
//  */
// export const restoreDeleted = async (layerId: number, objectId: string, versionName: string, versionChanges: OrganizedObjects[]): Promise<__esri.Graphic | null> => {
//     const featureLayer = new FeatureLayer({
//         url: `https://vmkyst03.azure.geodata.no/server/rest/services/BV/Branch_Versioning_DEV/FeatureServer/${layerId}`,
//         gdbVersion: versionName
//     })

//     const versionChange = versionChanges.find((change) => {
//         return change.layerId == layerId.toString()
//     });

//     if (!versionChange) {
//         return null;
//     }

//     const featureChange = versionChange.objects[objectId];
//     if (featureChange.type == "delete") {
//         const removedFeature = featureChange.originalGraphic;
//         if (!removedFeature) {
//             return null;
//         }
//         const result = await featureLayer.applyEdits({ addFeatures: [removedFeature] }, { globalIdUsed: true });
//         if (result.addFeatureResults[0].error) {
//             console.error("Error adding feature: ", result.addFeatureResults[0].error);
//             return null;
//         } else {
//             return removedFeature;
//         }
//     }

//     return null;
// };


// This does not work properly as the restoreRows endpoint throws an error working on a fix or workaround
export const restoreDeleted = async (layerId: number, objectId: number, versionGuid: string, versionChanges: OrganizedObjects[], sessionId?: string): Promise<__esri.Graphic | null> => {
    const versionServiceUrl = getVersionServiceUrl();
    const token = await getAGEToken();
    const changedVersion = versionChanges.find((change) => {
        return change.layerId == layerId.toString()
    });
    if (!changedVersion) {
        return null;
    }
    let restoredFeature = changedVersion.objects[objectId].originalGraphic;
    if (!restoredFeature) {
        return null;
    }

    const restoreInfo = [{
        layerId: layerId,
        objectIds: [objectId]
    }]

    const urlEncodedRestoreInfo = encodeURIComponent(JSON.stringify(restoreInfo));

    let activeSession = sessionId;
    if (!activeSession) {
        activeSession = getSessionID();
        await startReading(versionGuid, activeSession);
        await startEditing(versionGuid, activeSession);
    }

    activeSession = encodeURIComponent(activeSession);


    await fetch(versionServiceUrl + `/versions/${versionGuid}/restoreRows?f=json&sessionId=${activeSession}&rows=${urlEncodedRestoreInfo}`,
        {
            method: 'POST',
            headers: {
                'X-Esri-Authorization': 'Bearer ' + token
            },
        }).then(async (response) => {
            await response.json().then((data) => {
                if (!data.success) {
                    restoredFeature = undefined;
                }
            });
        }).catch((error) => {
            console.error(error);
            restoredFeature = undefined;
        });

    if (!sessionId) {
        await stopEditing(versionGuid, activeSession, false);
        await stopReading(versionGuid, activeSession);
    }

    return restoredFeature;
};