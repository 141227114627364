import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import * as symbolUtils from "@arcgis/core/symbols/support/symbolUtils.js";
import { useEffect, useState } from "react";
import { CreateObjectDefaultValuesConfig } from "../../../AppConfig";
import LightIcon from "../../../assets/light.svg?react";
import { getMapView } from "../../../utils/arcgisUtils";
import NfsButton from "../../genericComponents/NfsButton/NfsButton";
import "./ObjectTypeButton.css";

interface ObjectTypeButtonProps {
    title: string;
    layerName: string;
    presetAttributes: CreateObjectDefaultValuesConfig[];
    setActiveLayerId: (layerId: string) => void;
    setEditMode: (editMode: boolean) => void;
    setNewFeature: (feature: Graphic) => void;
}

const ObjectTypeButton = (props: ObjectTypeButtonProps) => {
    const { title, layerName, presetAttributes, setActiveLayerId, setEditMode, setNewFeature } = props;

    const [symbolHtmlElement, setSymbolHtmlElement] = useState<HTMLElement | null>(null);
    let dummyFeature: Graphic | null = null;

    useEffect(() => {
        dummyFeature = new Graphic({
            attributes: layer?.fields
                .map((field) => field.name)
                .reduce((acc, field) => ({ ...acc, [field]: null }), {}),
            layer: layer,
        });

        presetAttributes?.forEach((presetAttribute) => {
            dummyFeature!.attributes[presetAttribute.FieldName] = presetAttribute.Value;
        });

        symbolUtils.getDisplayedSymbol(dummyFeature).then((symbol) => {
            if (symbol) {
                symbolUtils.renderPreviewHTML(symbol, { size: 12 }).then((symbolElement) => {
                    setSymbolHtmlElement(symbolElement);
                });
            }
        });
    });

    const mapview = getMapView();
    if (!mapview) return;

    const layer = mapview.map.findLayerById(layerName) as FeatureLayer;

    const onClick = () => {
        setActiveLayerId(layerName);
        setEditMode(true);
        if (!dummyFeature) return;

        setNewFeature(dummyFeature);

        let latestObjectTypes = JSON.parse(localStorage.getItem("latestObjectTypes") ?? "[]") as string[];
        if (!latestObjectTypes) return;

        if (latestObjectTypes.includes(title)) {
            latestObjectTypes.splice(latestObjectTypes.indexOf(title), 1);
        }

        latestObjectTypes = [title, ...latestObjectTypes];
        localStorage.setItem("latestObjectTypes", JSON.stringify(latestObjectTypes));
    };

    return (
        <NfsButton className="create-object-type-button empty" key={title} onClick={onClick}>
            {symbolHtmlElement ? (
                <div dangerouslySetInnerHTML={{ __html: symbolHtmlElement?.outerHTML ?? "" }}></div>
            ) : (
                <LightIcon />
            )}
            {title}
        </NfsButton>
    );
};

export default ObjectTypeButton;
