import Graphic from "@arcgis/core/Graphic";
import { useEffect, useState } from "react";
import { AppConfig, LayersConfig } from "../../AppConfig";
import FeatureFieldWithLabel from "./FeatureFieldWithLabel";

interface FeatureViewerAttributesProps {
    selectedFeature: Graphic | null;
    isEditing: boolean;
    editFeatureAttribute: (field: string, value: any) => void;
    showAllAttributes: boolean;
    hideNonEditable?: boolean;
}

const FeatureViewerAttributes = (props: FeatureViewerAttributesProps) => {
    const { selectedFeature, isEditing, editFeatureAttribute, showAllAttributes, hideNonEditable } = props;
    const [layerConfig, setLayerConfig] = useState<LayersConfig | undefined>(undefined);

    useEffect(() => {
        const layer = AppConfig.Layers.find((l) => l.Name === selectedFeature?.layer.id);
        setLayerConfig(layer);
        console.log(selectedFeature?.attributes);
    }, [selectedFeature]);

    if (!selectedFeature) return null;

    return (
        <div className="feature-viewer-feature-attributes">
            {selectedFeature.attributes &&
                Object.keys(selectedFeature.attributes).map((key) => {
                    if (layerConfig?.ExcludeFields?.includes(key)) {
                        return null;
                    }
                    if (!layerConfig?.ImportantFields.includes(key)) {
                        return null;
                    }
                    return (
                        <FeatureFieldWithLabel
                            key={key}
                            attributeKey={key}
                            editFeatureAttribute={editFeatureAttribute}
                            isEditing={isEditing}
                            selectedFeature={selectedFeature}
                            hideNonEditable={hideNonEditable}
                        />
                    );
                })}
            {showAllAttributes &&
                selectedFeature.attributes &&
                Object.keys(selectedFeature.attributes).map((key) => {
                    if (layerConfig?.ExcludeFields?.includes(key)) {
                        return null;
                    }
                    if (layerConfig?.ImportantFields.includes(key)) {
                        return null;
                    }
                    return (
                        <FeatureFieldWithLabel
                            key={key}
                            attributeKey={key}
                            editFeatureAttribute={editFeatureAttribute}
                            isEditing={isEditing}
                            selectedFeature={selectedFeature}
                            hideNonEditable={hideNonEditable}
                        />
                    );
                })}
            <div className="feature-viewer-footer"></div>
        </div>
    );
};

export default FeatureViewerAttributes;
