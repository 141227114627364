import { Children } from "react";

interface TabPanelProps {
    children: React.ReactNode;
    active?: boolean;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, active } = props;

    return active ? Children.toArray(children) : null;
};

export default TabPanel;
