import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { readOnlyFields } from "../../constants/NauticalObjects";
import { GetCodedValueAndAlias } from "../../utils/arcgisUtils";
import FeatureField from "./FeatureField";

interface FeatureFieldWithLabelProps {
    selectedFeature: Graphic;
    isEditing: boolean;
    editFeatureAttribute: (field: string, value: any) => void;
    attributeKey: string;
    hideNonEditable?: boolean;
}

const FeatureFieldWithLabel = (props: FeatureFieldWithLabelProps) => {
    const { attributeKey, selectedFeature, isEditing, editFeatureAttribute, hideNonEditable } = props;

    const fieldname = GetCodedValueAndAlias(
        selectedFeature.layer,
        attributeKey,
        selectedFeature.attributes[attributeKey]
    )[0];

    const fieldInfo = (selectedFeature.layer as FeatureLayer)?.fields.find((field) => field.name === fieldname);

    if (hideNonEditable && (readOnlyFields.includes(attributeKey) || !fieldInfo?.editable)) {
        return null;
    }

    return (
        <div className="feature-viewer-feature-attribute">
            <span className="feature-viewer-key text-left">{fieldname}</span>
            <FeatureField
                field={attributeKey}
                layer={selectedFeature.layer}
                attributes={selectedFeature.attributes}
                isEditing={isEditing}
                onChange={(value) => {
                    editFeatureAttribute(attributeKey, value);
                }}
            />
        </div>
    );
};

export default FeatureFieldWithLabel;
