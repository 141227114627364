import * as symbolUtils from "@arcgis/core/symbols/support/symbolUtils.js";
import { useContext, useEffect, useRef, useState } from "react";
import LeftArrowIcon from "../../assets/arrow-left.svg?react";
import CloseIcon from "../../assets/close.svg?react";

import { useDispatch, useSelector } from "react-redux";
import { AppConfig, LayersConfig } from "../../AppConfig";
import { AppContext } from "../../Context";
import { setFeatureViewerIsEditing, setFeatureViewerOpen } from "../../store/appSlice";
import { StoreState } from "../../store/rootReducer";
import { highlightFeature, updateFeatureAttributes } from "../../utils/arcgisUtils";
import NfsButton from "../genericComponents/NfsButton/NfsButton";
import "./FeatureViewer.css";
import FeatureViewerContent from "./FeatureViewerContent";
import PaperPlaneIcon from "../../assets/paper-plane.svg?react";
import TrashCanIcon from "../../assets/trash-can.svg?react";

const FeatureViewer = () => {
    const dispatch = useDispatch();

    const [panelClass, setPanelClass] = useState<string>("collapsed");
    const [showAllAttributes, setShowAllAttributes] = useState<boolean>(false);
    const [symbolHtmlElement, setSymbolHtmlElement] = useState<HTMLElement | null>(null);

    const isEditing = useSelector((state: StoreState) => state.app.featureViewerIsEditing);

    const featureViewerOpen = useSelector((state: StoreState) => state.app.featureViewerOpen);

    const layerConfig = useRef<LayersConfig>();
    const highlightRef = useRef<IHandle>();
    const unChangedFeature = useRef<__esri.Graphic>();

    const appContext = useContext(AppContext);
    const selectedFeature = appContext?.selectedFeature.value;

    // Due to css behavior with animations to display none and vice versa
    // an additional class is needed to allow panel to slide out
    const toggleOpen = () => {
        if (panelClass === "") {
            closePanel();
        } else if (panelClass === "collapsed") {
            openPanel();
        }
    };

    const openPanel = () => {
        if (panelClass === "") {
            return;
        }
        setPanelClass("opening");
        setTimeout(() => {
            setPanelClass("");
        }, 100);
    };

    const closePanel = () => {
        setPanelClass("collapsed");
        dispatch(setFeatureViewerOpen(false));
    };

    const unselectFeature = () => {
        closePanel();
        appContext?.selectedFeature.set(null);
    };

    const editFeatureAttribute = (field: string, value: any) => {
        if (!selectedFeature) {
            return;
        }
        const clonedFeature = selectedFeature.clone();
        clonedFeature.attributes[field] = value;

        appContext?.selectedFeature.set(clonedFeature);
    };

    const saveFeature = () => {
        if (!selectedFeature) {
            dispatch(setFeatureViewerIsEditing(false));
            return;
        }
        updateFeatureAttributes(selectedFeature).then((success: boolean) => {
            if (success) {
                dispatch(setFeatureViewerIsEditing(false));
            } else {
                console.log("Error saving feature");
            }
        });
    };

    const toggleEdit = (edit: boolean) => {
        dispatch(setFeatureViewerIsEditing(edit));
        if (!edit) {
            if (unChangedFeature.current) {
                appContext?.selectedFeature.set(unChangedFeature.current);
            }
        } else {
            unChangedFeature.current = selectedFeature?.clone();
        }
    };

    const openInFDV = (GISOBJID: number) => {
        const gisobjidString = GISOBJID.toString().padStart(5, "0");
        window.open(`${AppConfig.FDV.BaseUrl}${AppConfig.FDV.ObjectPropertiesBase}${gisobjidString}`);
    };

    useEffect(() => {
        if (featureViewerOpen) {
            openPanel();
        } else {
            closePanel();
        }
    }, [featureViewerOpen]);

    useEffect(() => {
        console.log(selectedFeature);
    }, []);

    useEffect(() => {
        if (selectedFeature) {
            highlightFeature(selectedFeature).then((handle) => {
                if (handle) {
                    highlightRef.current = handle;
                }
            });
            openPanel();
            layerConfig.current = AppConfig.Layers.find((layerConfig) => layerConfig.Name === selectedFeature.layer.id);
            symbolUtils.getDisplayedSymbol(selectedFeature).then((symbol) => {
                if (symbol) {
                    symbolUtils.renderPreviewHTML(symbol, { size: 12 }).then((symbolElement) => {
                        setSymbolHtmlElement(symbolElement);
                    });
                }
            });
        } else {
            setShowAllAttributes(false);
            dispatch(setFeatureViewerIsEditing(false));
            closePanel();
        }

        console.log(selectedFeature);
        return () => {
            if (highlightRef.current) {
                highlightRef.current.remove();
            }
        };
    }, [selectedFeature]);

    useEffect(() => {
        return () => {
            if (highlightRef.current) {
                highlightRef.current.remove();
            }
        };
    }, []);

    if (!selectedFeature) {
        return null;
    }

    return (
        <div className="feature-viewer-container">
            <div className={`feature-viewer ${panelClass}`}>
                <div className="feature-viewer-header">
                    <span className="flex-row small-gap" style={{ width: "100%" }}>
                        <NfsButton
                            onClick={() => {
                                setShowAllAttributes(!showAllAttributes);
                            }}
                        >
                            {showAllAttributes ? "Vis færre" : "Vis mer"}
                        </NfsButton>
                        <NfsButton
                            onClick={() => {
                                toggleEdit(!isEditing);
                            }}
                        >
                            {isEditing ? "Avbryt" : "Rediger"}
                        </NfsButton>
                    </span>
                    <button className="empty-button" onClick={unselectFeature}>
                        <CloseIcon />
                    </button>
                </div>
                <FeatureViewerContent
                    // selectedFeature={selectedFeature}
                    editFeatureAttribute={editFeatureAttribute}
                    isEditing={isEditing}
                    showAllAttributes={showAllAttributes}
                    // layerConfig={layerConfig}
                    symbolHtmlElement={symbolHtmlElement}
                    feature={selectedFeature}
                />
                <div className="feature-viewer-footer">
                    {isEditing && (
                        <>
                            <NfsButton onClick={saveFeature}>
                                <PaperPlaneIcon />
                                Sett til prosjektert
                            </NfsButton>
                            <NfsButton outlined onClick={unselectFeature}>
                                <TrashCanIcon />
                                Forkast
                            </NfsButton>
                        </>
                    )}
                    {!isEditing &&
                        selectedFeature?.attributes["gis_obj_id"] &&
                        selectedFeature?.attributes["gis_obj_id"] > 0 && (
                            <NfsButton
                                onClick={() => {
                                    openInFDV(selectedFeature.attributes["gis_obj_id"]);
                                }}
                            >
                                Åpne i FDV
                            </NfsButton>
                        )}
                </div>
            </div>
            <button className={`feature-viewer-collapse-button ${panelClass}`} onClick={toggleOpen}>
                <LeftArrowIcon />
            </button>
        </div>
    );
};

export default FeatureViewer;
