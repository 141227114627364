import "./NfsButton.css";

interface NfsButtonProps {
    onClick: () => void;
    disabled?: boolean;
    active?: boolean;
    selected?: boolean;
    className?: string;
    round?: boolean;
    inverted?: boolean;
    outlined?: boolean;
    shadow?: boolean;
    children?: React.ReactNode;
    flexColumn?: boolean;
    small?: boolean;
}

/**
 * A button with different styles and options
 * Be careful when combining options, as some might not look good together or override each other
 * Will evolve over time as more options are needed
 * Might have to be split into multiple components or render options based on props
 * @param props
 * @returns
 */
const NfsButton = (props: NfsButtonProps) => {
    let additionalClasses = props.className ? props.className : "";
    if (props.active) {
        additionalClasses += " active";
    }
    if (props.selected) {
        additionalClasses += " selected";
    }
    if (props.round) {
        additionalClasses += " round";
    }
    if (props.inverted) {
        additionalClasses += " inverted";
    }
    if (props.outlined) {
        additionalClasses += " outline";
    }
    if (props.shadow) {
        additionalClasses += " shadow";
    }
    if (props.flexColumn) {
        additionalClasses += " column";
    }
    if (props.disabled) {
        additionalClasses += " disabled";
    }
    if (props.small) {
        additionalClasses += " small";
    }

    return (
        <button
            className={`nfs-button 
            ${additionalClasses}`}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
};

export default NfsButton;
