import "./ObjectGroup.css";

interface ObjectGroupProps {
    title: string;
    children: React.ReactNode;
}

const ObjectGroup = (props: ObjectGroupProps) => {
    const { title, children } = props;

    return (
        <div className="create-object-group">
            <span className="create-object-group-title">{title}</span>
            {children}
        </div>
    );
};

export default ObjectGroup;
